<template>
  <div class="d-flex justify-space-between">
    <h1 class="">Tipos de combustíveis</h1>
  </div>
  <v-data-table :headers="headers" :items="items" :items-per-page="-1" :loading="isLoadingTable" item-value="id" class="elevation-1" hide-default-footer>
    <template v-slot:[`item.status`]="{ item }">
      <div class="status-switch">
        <v-switch :disabled="isLoadingTable" v-model="item.status" v-on:update:model-value="updateFuel(item.id, { status: item.status })" :false-value="0" :true-value="1" color="orange" :label="item.status ? 'Ativo' : 'Inativo'"></v-switch>
      </div>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";
import { FuelType } from "@/models/fuelTypes/FuelType";
import { FuelTypesIndexResponse } from "@/dtos/fuelTypes/FuelTypesIndexResponse";
import { FuelTypeStoreRequest } from "@/dtos/fuelTypes/FuelTypeStoreRequest";

@Options({
  components: {},
})
export default class FuelTypesView extends Vue {
  public items: Array<FuelType> = [];

  public headers = [
    { title: "Nome", align: "start", key: "name", sortable: false },
    { title: "Status", align: "end", key: "status", sortable: false },
  ];

  public isLoadingTable = true;

  mounted(): void {
    this.willMountDataPage();
  }

  async willMountDataPage(): Promise<void> {
    const response = await services.FuelTypesService.getAll();

    this.unMountRequest(response);
    this.changeLoading();
  }

  changeLoading(): void {
    this.isLoadingTable = !this.isLoadingTable;
  }

  private unMountRequest(response: FuelTypesIndexResponse) {
    if (response.data) {
      this.items = response.data;
    }
  }

  async updateFuel(id: number, data: FuelTypeStoreRequest) {
    this.changeLoading();
    const response = await services.FuelTypesService.update(data, id);
    if (!response.success) {
      const item = this.items.find((item) => item.id === id);
      if (item) {
        item.status = item.status === 1 ? 0 : 1;
      }
    }
    this.changeLoading();
  }
}
</script>
<style lang="css">
.status-switch {
  display: inline-flex;
}
</style>

import "mutationobserver-shim";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { VueReCaptcha } from "vue-recaptcha-v3";
import "@/assets/scss/global.scss";
import "@/assets/index.css";
import "vuetify/styles";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { createVuetify } from "vuetify";
import * as Sentry from "@sentry/vue";

const vuetify = createVuetify({
  components: {
    ...components,
  },
  directives,
  defaults: {
    VDataTable: {
      fixedHeader: true,
      noDataText: "Resultados não encontrados",
    },
  },
});

const app = createApp(App)
  .use(store)
  .use(vuetify)
  .use(router)
  .use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_SITE_KEY,
    loaderOptions: {
      useRecaptchaNet: true,
    },
  });

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DNS,
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

app.mount("#app");

<template>
  <div class="d-flex justify-space-between">
    <v-container fluid>
      <v-row class="fill-height" align="center" justify="center">
        <v-col>
          <h1 class="">Itens do checklist</h1>
        </v-col>
        <v-col align="end">
          <a class="text-decoration-none">
            <v-btn class="text-white" color="grey" href="/items-checklist" v-bind:disabled="btn_disabled">Cancelar</v-btn>
            <v-btn class="text-white" color="#D44E11" @click="newItem" v-bind:disabled="btn_disabled">Novo Item</v-btn>
            <v-btn class="text-white" :color="movida_color" @click="save" v-bind:disabled="btn_disabled" :loading="loading">Salvar</v-btn>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <v-container fluid :hidden="hide_container">
    <v-card :color="main_card_color">
      <v-row class="fill-height" align="center" justify="center">
        <v-col>
          <v-card :color="son_card_color" class="min-height-card">
            <draggable :list="data_list" itemKey="id" class="list-items" ref="itemsList">
              <template #item="{ element }">
                <v-container class="pa-1">
                  <v-card class="mx-12 grab-cursor" :color="son_card_color" @dblclick="showItemDetails(element)">
                    <v-col class="mx-auto">
                      <v-icon v-if="element.status" color="green"> mdi-check-circle </v-icon>
                      <v-icon v-else color="red"> mdi-close-circle </v-icon>
                      <v-span>{{ element.name.length > 48 ? element.name.slice(0, 45) + "..." : element.name }}</v-span>
                    </v-col>
                  </v-card>
                </v-container>
              </template>
            </draggable>
          </v-card>
        </v-col>
        <v-col>
          <v-card :color="son_card_color" class="min-height-card list-items" v-if="hidden_control">
            <v-container class="pa-1">
              <v-text-field class="input-borders" label="Nome do item" hide-details="auto" v-model="item_name" :rules="name_rules" ref="ref_item_name"></v-text-field>
              <v-combobox v-model="status" :items="status_choices" density="compact" label="Status" :rules="status_rules" ref="ref_status"></v-combobox>
              <v-combobox v-model="damage" :items="damage_choices" density="compact" label="Avaria" :rules="damage_rules" ref="ref_damage"></v-combobox>
              <h3 :style="{ color: movida_color }">Selecione os grupos que percentem ao Item do checklist</h3>
              <v-combobox ref="groupComboboxElement" clearable multiple v-model="selected_groups" :items="all_groups" density="compact" label="Grupos" @click:clear="onSelectedGroupsClear" @update:model-value="onSelectedGroupsChange" @update:focused="onGroupsComboboxFocusChange">
                <template v-slot:selection></template>
                <template v-slot:prepend-item>
                  <v-list :items="groupOptions" @click:select="onGroupComboboxOption">
                    <template v-slot:item="{ props }">
                      <v-list-item v-bind="props" title="">
                        <v-checkbox v-model="groupOptionModel" :label="props.title" :value="props.value" :style="{ height: '3.5em' }" readonly></v-checkbox>
                      </v-list-item>
                    </template>
                  </v-list>
                </template>
              </v-combobox>
              <v-chip-group column class="px-3">
                <v-chip v-for="group in selected_groups" :key="group">
                  {{ group }}
                </v-chip>
              </v-chip-group>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>

  <v-snackbar v-model="snackbar" :timeout="timeout">
    <v-icon class="ml-1"> mdi-information-outline </v-icon> {{ success_error_msg }}
    <template v-slot:actions>
      <v-btn variant="text" @click="closeSnack"> Fechar </v-btn>
    </template>
  </v-snackbar>
  <loading-overlay ref="loadingOverlay" />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";
import draggable from "vuedraggable";
import { ItemChecklist } from "@/models/itemChecklist/ItemChecklist";
import LoadingOverlay from "@/components/LoadingOverlay/LoadingOverlay.vue";

@Options({
  components: {
    draggable,
    LoadingOverlay,
  },
})
export default class ItemChecklistIndexComponent extends Vue {
  public name_rules = [(value: any) => !!value || "Required.", (value: string) => (value && value.length >= 3) || "Min 3 characters"];
  public status_rules = [(value: any) => !!value || "Status é obrigatório", (value: any) => this.status_choices.includes(value) || "Status inválido"];
  public damage_rules = [(value: any) => !!value || "Avaria é obrigatório", (value: any) => this.damage_choices.includes(value) || "Avaria inválido"];
  public data_list: any = [];
  public status_choices = ["Inativo", "Ativo"];
  public damage_choices = ["Não", "Sim"];
  public status = "";
  public damage = "";
  public item_id = 0;
  public item_name = "";
  public selected_groups = [];
  public all_groups = [];
  public hidden_control = false;
  public snackbar = false;
  public btn_disabled = false;
  public loading = false;
  public success_error_msg = "";
  public main_card_color = "#ebeae8";
  public son_card_color = "#f5f5f5";
  public movida_color = "#ff5000";
  public hide_container = true;
  public groupOptions = [
    {
      title: "Selecionar todos",
      value: "all",
    },
  ];

  public groupOptionModel: string[] = [];

  mounted(): void {
    this.willMountDataPage();
  }

  /*
    Oculta/exibe a animação de loading e a parte onde os dados são exibidos até que os dados sejam carregados
    e também torna os botões clicáveis ou não
  */
  async loadingOverlay(loading = true): Promise<void> {
    const loadingOverlay = this.$refs.loadingOverlay as any;
    if (loading) {
      loadingOverlay.show();
    } else {
      loadingOverlay.hide();
    }

    this.btn_disabled = loading;
    this.hide_container = loading;
  }

  async willMountDataPage(): Promise<void> {
    // exibe a animação de loading enquanto os dados são carregados
    await this.loadingOverlay(true);

    const response = await services.ItemChecklistService.getAll();
    const all_groups = await services.ItemChecklistService.getAllGroups("1");

    if (response.data) {
      // Ordena os dados com base no campo 'order'
      this.data_list = response.data.sort((a, b) => a.order - b.order);
    }

    if (all_groups.data) {
      this.all_groups = [...all_groups.data] as any;
    }

    // oculta a animação de loading após os dados serem carregados
    await this.loadingOverlay(false);

    // scroll top para lista de checkist itens
    const itemsListElement = this.$refs.itemsList as any;
    itemsListElement?.targetDomElement?.scrollTo({ top: 0 });

    // scroll top para opções do item checklist
    if (this.hidden_control) {
      this.hidden_control = false;
      this.$nextTick(() => {
        this.hidden_control = true;
      });
    }
  }

  showItemDetails(element: any) {
    this.damage = element.avaria ? this.damage_choices[1] : this.damage_choices[0];
    this.status = element.status ? this.status_choices[1] : this.status_choices[0];
    this.item_name = element.name;
    this.item_id = element.id;
    this.selected_groups = element.groups;
    this.hidden_control = true;
  }

  onSelectedGroupsClear() {
    const groupComboboxElement = this.$refs.groupComboboxElement as any;
    groupComboboxElement.blur();
  }

  onGroupsComboboxFocusChange(focused: boolean) {
    if (focused) {
      this.onSelectedGroupsChange(this.selected_groups);
    }
  }

  onSelectedGroupsChange(groups: string[]) {
    const allSelected = this.selected_groups.length === this.all_groups.length;

    if (allSelected) {
      if (!this.groupOptionModel.includes("all")) {
        this.groupOptionModel.push("all");
      }
    } else {
      this.groupOptionModel = this.groupOptionModel.filter((key) => key !== "all");
    }

    this.selected_groups = this.selected_groups.filter((group) => this.all_groups.includes(group));
  }

  onGroupComboboxOption(option: any) {
    const isActive = this.groupOptionModel.includes(option.id);

    if (isActive) {
      this.groupOptionModel = this.groupOptionModel.filter((key) => key !== option.id);
    } else {
      this.groupOptionModel.push(option.id);
    }

    const selectAll = this.groupOptionModel.includes("all");
    const unselectAll = option.id === "all" && !selectAll;
    const groupComboboxElement = this.$refs.groupComboboxElement as any;

    if (selectAll || unselectAll) {
      groupComboboxElement.blur();
    }

    if (selectAll) {
      this.selected_groups = [...this.all_groups];
    }

    if (unselectAll) {
      this.selected_groups = [];
    }
  }

  newItem() {
    this.damage = this.damage_choices[0];
    this.status = this.status_choices[1];
    this.item_name = "";
    this.item_id = 0;
    this.selected_groups = [];
    this.hidden_control = true;
  }

  async checkValidations(is_storing = false): Promise<boolean> {
    let validation_ok = true;

    if (this.item_id == 0 && !is_storing) {
      return validation_ok;
    }

    let item_name = (await this.$refs.ref_item_name) as any;
    let status = (await this.$refs.ref_status) as any;
    let damage = (await this.$refs.ref_damage) as any;

    item_name = await item_name.validate();
    status = await status.validate();
    damage = await damage.validate();

    const validation_list: any[] = [];
    validation_list.push(item_name.length);
    validation_list.push(status.length);
    validation_list.push(damage.length);

    validation_ok = !validation_list.some((v) => v > 0);

    const is_any_selected_group_not_in_all_groups = this.selected_groups.some((group) => !this.all_groups.includes(group));
    const item_groups = this.data_list.find((item: any) => item.item_id === this.item_id)?.groups;

    if (is_any_selected_group_not_in_all_groups) {
      await this.showSnack("Algum grupo selecionado não está na lista de grupos, remova antes de salvar");
      validation_ok = false;
    } else if (item_groups?.length > 0 && this.selected_groups.length == 0) {
      await this.showSnack("Não é possível remover todos os grupos, selecione pelo menos um");
      validation_ok = false;
    }

    return validation_ok;
  }

  async saveOrder(): Promise<void> {
    const position: { position: any[] } = { position: this.data_list.map((item: ItemChecklist, index: number) => ({ id: item.id, order: index + 1 })) };

    const update_order_response = await services.ItemChecklistService.updateOrder(position);
    await this.showSnack(update_order_response.msg);
  }

  async updateOrStoreItemsData(update_or_store = "update"): Promise<void> {
    const status = this.status == this.status_choices[0] ? 0 : 1;
    const damage = this.damage == this.damage_choices[0] ? 0 : 1;

    const item_data = {
      name: this.item_name,
      status: status,
      avaria: damage,
    };

    let update_store_response;
    if (update_or_store == "update") {
      update_store_response = await services.ItemChecklistService.update(item_data, this.item_id);
    } else {
      update_store_response = await services.ItemChecklistService.storeNew(item_data);
      this.item_id = update_store_response?.data?.id ?? this.item_id;
    }

    let msg = update_or_store == "update" ? "Erro ao atualizar dados do item" : "Erro ao gravar dados do item";
    if (update_store_response.success == true) {
      msg = "Dados do item gravados com sucesso";

      if (update_or_store == "update") {
        msg = "Dados do item atualizados com sucesso";
      }
    }

    await this.showSnack(msg);
  }

  async saveGroups(): Promise<void> {
    const groups_data = {
      groups: this.selected_groups.map((group_id) => ({ group_id })),
    };

    if (groups_data?.groups?.length > 0) {
      const update_groups_response = await services.ItemChecklistService.updateGroups(groups_data, this.item_id);
      await this.showSnack(update_groups_response.msg);
    }
  }

  async update(): Promise<void> {
    const validation_ok = await this.checkValidations();
    if (!validation_ok) {
      return;
    }

    this.btn_disabled = true;
    this.loading = true;

    await this.saveOrder();

    // se nenhum item foi "aberto", será gravada somente a ordenação
    if (this.item_id == 0) {
      this.btn_disabled = false;
      this.loading = false;
      return;
    }

    await this.updateOrStoreItemsData("update");
    await this.saveGroups();
    await this.willMountDataPage();

    // atualiza os grupos selecionados que são exibidos na tela depois que os dados são gravados no banco
    this.selected_groups = this.data_list.find((item: any) => item.item_id === this.item_id).groups;

    this.btn_disabled = false;
    this.loading = false;
  }

  async storeNew(): Promise<void> {
    const validation_ok = await this.checkValidations(true);
    if (!validation_ok) {
      return;
    }

    this.btn_disabled = true;
    this.loading = true;

    await this.saveOrder();
    await this.updateOrStoreItemsData("store");
    await this.saveGroups();
    await this.willMountDataPage();

    // atualiza os grupos selecionados que são exibidos na tela depois que os dados são gravados no banco
    this.selected_groups = this.data_list.find((item: any) => item.item_id === this.item_id)?.groups;

    this.btn_disabled = false;
    this.loading = false;
  }

  async save(): Promise<void> {
    try {
      if (this.item_id == 0 && this.hidden_control) {
        await this.storeNew();
      } else {
        await this.update();
      }
    } catch (error) {
      this.btn_disabled = false;
      this.loading = false;
      await this.showSnack("Algo deu errado ao salvar os dados, tente novamente");
    }
  }

  closeSnack() {
    this.snackbar = false;
  }

  async showSnack(msg: string): Promise<void> {
    if (msg != "") {
      this.success_error_msg = msg;
      this.snackbar = true;
    }
  }
}
</script>

<style lang="css">
.min-height-card {
  min-height: 114px;
}

.grab-cursor {
  cursor: grab;
}

.list-items {
  height: 77vh;
  overflow-y: auto !important;
}

.input-borders {
  padding-bottom: 21px;
}

.custom-chip {
  margin-right: 15px;
  width: 52px;
}
</style>

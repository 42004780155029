import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";

interface RefreshToken {
  status: number;
  data: {
    hashToken: string;
  };
}

export abstract class BaseService {
  protected instance: AxiosInstance;
  protected token: string;
  public baseURL: string;

  public constructor(baseURL: string, token: string) {
    this.baseURL = process.env.VUE_APP_SERVICE_URL;
    if (baseURL.length > 0) {
      this.baseURL = baseURL;
    }
    this.instance = axios.create({
      baseURL: this.baseURL,
    });
    this.token = token;
    this.initializeResponseInterceptor();
  }

  getConfigHeaders(): object {
    return {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("BEARER_TOKEN"),
        Accept: "application/json",
      },
    };
  }

  /**
   * initializeResponseInterceptor
   */
  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use((response) => {
      if (response.headers && response.headers.authorization) {
        this.token = (response.headers.authorization as string).split(" ")[1];
        localStorage.setItem("hashToken", this.token);
      }
      return response;
    }, this.handleError);
  };
  /**
   * handleError
   * @param error
   */
  private handleError = async (err: any) => {
    try {
      if (err.response?.status === 401) {
        localStorage.removeItem("BEARER_TOKEN");
        window.location.reload();
      }
      err = Object.assign(new Error(), { message: err.response.data.message });
    } catch (e) {
      console.log("erro");
    }

    return Promise.reject(err);
  };

  /**
   * refreshToken
   * @private
   */
  private async refreshToken(): Promise<RefreshToken> {
    const refreshTokenRequest = {
      hashToken: this.token,
    };
    return this.instance.post(`${this.baseURL}/User/RenewToken`, refreshTokenRequest, {});
  }

  /**
   * @param dataRequest
   * @param route
   */
  async put(route: string, dataRequest: object) {
    return await this.instance.put(route, dataRequest, this.getConfigHeaders());
  }

  /**
   * @param dataRequest
   * @param route
   */
  async post(route: string, dataRequest: object) {
    return await this.instance.post(route, dataRequest, this.getConfigHeaders());
  }

  /**
   * @param route
   */
  async get(route: string) {
    return await this.instance.get(route, this.getConfigHeaders());
  }

  /**
   * @param route
   */
  async delete(route: string) {
    return await this.instance.delete(route, this.getConfigHeaders());
  }
}

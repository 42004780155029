<template>
  <v-navigation-drawer :value="show" app permanent color="#F6F8F9" width="240">
    <div id="container-top-navigation" class="mx-3">
      <img class="my-4 ml-3" alt="Logo Movida" src="@/assets/brand.svg" />
      <v-divider class="my-3"></v-divider>
      <v-row>
        <v-list density="default" class="navbar-left">
          <v-list-item v-for="(item, i) in items" :key="i" :value="item" color="primary" :href="item.href">
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-row>
    </div>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import routes from "@/constants/routes";

@Options({
  components: {},
})
export default class NavbarLeft extends Vue {
  public show = true;
  public location: any;
  public moduleSelectedId = 0;

  compare(a: any, b: any): any {
    if (a.menu_order < b.menu_order) {
      return -1;
    }
    if (a.menu_order > b.menu_order) {
      return 1;
    }
    return 0;
  }

  getRoute(routeName: string) {
    const { url } = Object.values(routes).find(({ name }) => name === routeName) || { url: "" };

    return url;
  }

  public items = [
    { text: "Início", icon: "mdi-monitor-multiple", href: "/admin" },
    { text: "Tipos de checklist", icon: "mdi-monitor-multiple", href: "/tipos-checklist" },
    { text: "Checklist Itens de Conferência", icon: "mdi-monitor-multiple", href: "/items-checklist" },
    { text: "Tipos de combustíveis", icon: "mdi-monitor-multiple", href: "/tipos-combustiveis" },
  ];
}
</script>
<style lang="css" scoped>
.v-list-item:hover {
  color: #ff5000 !important;
}

.active {
  background-color: white !important;
  color: #ff5000 !important;
}
.nav-header {
  padding: 0 12px;
}
.navbar-left .v-list-item--variant-text {
  background: #f6f8f9 !important;
}
.text-primary {
  color: #ff5000 !important;
}
</style>

<template>
  <v-row>
    <v-col cols="8">
      <div class="d-flex justify-space-between">
        <h1 class="titleSt">Componente Preview List</h1>
        <a class="text-decoration-none">
          <v-btn class="text-white" color="grey" @click="$emit('close-modal')">Cancelar</v-btn>
          <v-btn class="text-white" color="#ff5000" @click="store" v-bind:disabled="btn_disabled">Salvar</v-btn>
        </a>
      </div>
      <div class="mainInputs">
        <v-text-field v-model="title" label="Título" :rules="rules" hide-details="auto" @keyup="onInputChange()"></v-text-field>
      </div>
    </v-col>

    <v-col cols="4">
      <custom-img-component :imageSrc="imageSrc" />
    </v-col>
  </v-row>

  <v-snackbar v-model="snackbar" :timeout="timeout">
    <v-icon class="ml-1"> mdi-information-outline </v-icon> {{ text }}
    <template v-slot:actions>
      <v-btn variant="text" @click="closeSnack"> Fechar </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import messages from "@/constants/messages";
import CustomImgComponent from "@/components/CustomImg/CustomImgComponent.vue";

@Options({
  components: {
    CustomImgComponent,
  },
})
export default class PreviewListComponent extends Vue {
  public title = "";
  public rules = [(value: any) => !!value || "Required.", (value: string) => (value && value.length >= 3) || "Min 3 characters"];
  public typeFragment = "CustomPreviewListFragment";
  public messages: any = {};
  public isLoadingTable = true;
  public needleSearch: any;
  public snackbar = false;
  public text = "";
  public timeout = 10000;
  public btn_disabled = true;
  public generatedJson: any;
  public form: any;
  public imageSrc = require("@/assets/CustomComponentsImgExamples/PreviewList.png");

  mounted(): void {
    this.messages = messages;
    this.$emit("mounted", this);
  }

  changeLoading(): void {
    this.isLoadingTable = !this.isLoadingTable;
  }

  closeSnack(): void {
    this.snackbar = false;
  }

  onInputChange() {
    this.btn_disabled = true;
    if (this.title.length >= 3) {
      this.btn_disabled = false;
    }
  }

  public updateAttributes(data: any) {
    this.title = data.title;
  }

  async convertToJson(): Promise<void> {
    if (this.typeFragment && this.title.length >= 3) {
      try {
        const json = {
          title: this.title,
          typeFragment: this.typeFragment,
          hidden_component_id: 8, // 8 é componente PREVIEW LIST
        };

        this.generatedJson = json as any;
        return this.generatedJson;
      } catch (error) {
        console.error("Erro na conversão para base64 (preview list)", error);
      }
    }
  }

  async store(): Promise<void> {
    try {
      const jsonPromise = this.convertToJson();
      const json = await jsonPromise;
      const success_error_msg = "Dados gravados";
      const data = {
        success_error_msg: success_error_msg,
        json: json,
      };

      this.$emit("generatedJson", data);
      this.$emit("close-modal");
    } catch (error) {
      const successErrorMsg = "Erro ao tentar gravar os dados";
      console.log("Erro ao gravar os dados (preview list)", error);
    }
  }
}
</script>

<style lang="css">
.default-data-base {
  border: solid #efecec 0.4px;
}

.w-3 {
  width: 10px;
}

.h-3 {
  height: 10px;
}

.mainInputs:not(:last-child) {
  margin-bottom: 15px;
}

.hiddenInput {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  pointer-events: none;
}

.newFileInput {
  height: 60px;
}

.titleSt {
  margin-right: 200px;
}
</style>

import { createStore } from "vuex";

export default createStore({
  state: {
    authenticated: false,
    buttonsLabels: ["Salvar", "Editar", "Cancelar"],
    tokens: {
      recaptcha: null,
      application: null,
      bearer: null,
    },
    user: "",
    modules: "",
  },
  getters: {
    isAuthenticated: (state) => {
      return state.tokens.application != null && state.tokens.recaptcha != null && state.tokens.bearer != null;
    },
    tokens: (state) => state.tokens,
    user: (state) => (state.user ? JSON.parse(state.user) : {}),
    modules: (state) => (state.modules ? JSON.parse(state.modules) : []),
  },
  mutations: {
    SET_RECAPTCHA_TOKEN(state, recaptchaToken) {
      state.tokens.recaptcha = recaptchaToken;
    },
    SET_BEARER_TOKEN(state, applicationToken) {
      state.tokens.application = applicationToken;
    },
    SET_USER_TOKEN(state, bearerToken) {
      state.tokens.bearer = bearerToken;
    },
    AUTH(state, isAuth) {
      state.authenticated = isAuth;
    },
    SET_DATA_USER(state, userData: object) {
      state.user = JSON.stringify(userData);
    },
    SET_MODULE_LIST(state, moduleList: object) {
      state.modules = JSON.stringify(moduleList);
    },
  },
  actions: {},
  modules: {},
});

<template>
  <v-row>
    <v-col cols="8">
      <div class="horizontalScrollLocked">
        <div class="d-flex justify-space-between">
          <h1 class="titleSt">Componente Opções Personalizadas</h1>
          <a class="text-decoration-none">
            <v-btn class="text-white" color="grey" @click="$emit('close-modal')">Cancelar</v-btn>
            <v-btn class="text-white" color="#ff5000" @click="store" v-bind:disabled="btn_disabled">Salvar</v-btn>
          </a>
        </div>
        <div class="mainInputs">
          <v-text-field v-model="title" label="Título" :rules="rules" hide-details="auto" @keyup="onInputChange()"></v-text-field>
        </div>
        <div>
          <v-switch :color="movida_color" v-model="hasObsField" @change="verifyBtnDisabled" :label="'Possui campo Observação? ' + (hasObsField ? 'Sim' : 'Não')"></v-switch>
        </div>
        <div>
          <hr />
          <h2>Lista de perguntas e opções</h2>
          <div>
            <div v-for="item in items" :key="item.id" class="mainInputs">
              <v-row>
                <v-col cols="12">
                  <v-text-field outlined class="questionElement1" v-model="item.question" label="Pergunta" :rules="rules" hide-details="auto" @keyup="onInputChange()">
                    <template v-slot:append>
                      <v-icon v-if="item.id != 1" color="red" @click="removeItem(item.id)"> mdi-minus </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="11">
                  <v-text-field outlined class="questionElement2" v-model="item.positive" label="Opção positiva" :rules="rules" hide-details="auto" @keyup="onInputChange()">
                    <template v-slot:append> </template>
                  </v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-checkbox class="questionElement2" v-model="item.photo_positive" @change="onlyOneCheckedBox(item.id, 'positive')"></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="11">
                  <v-text-field outlined class="questionElement2" v-model="item.negative" label="Opção negativa" :rules="rules" hide-details="auto" @keyup="onInputChange()">
                    <template v-slot:append> </template>
                  </v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-checkbox class="questionElement2" v-model="item.photo_negative" @change="onlyOneCheckedBox(item.id, 'negative')"></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="11">
                  <v-text-field outlined class="questionElement3" v-model="item.neutral" label="Opção neutra" :rules="rules" hide-details="auto" @keyup="onInputChange()">
                    <template v-slot:append> </template>
                  </v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-checkbox class="questionElement2" v-model="item.photo_neutral" @change="onlyOneCheckedBox(item.id, 'neutral')"></v-checkbox>
                </v-col>
              </v-row>
              <hr class="hrMargin" />
            </div>
            <v-btn @click="addItem">Adicionar pergunta</v-btn>
          </div>
        </div>
      </div>
    </v-col>

    <v-col cols="4">
      <custom-img-component :imageSrc="imageSrc" />
    </v-col>
  </v-row>

  <v-snackbar v-model="snackbar" :timeout="timeout">
    <v-icon class="ml-1"> mdi-information-outline </v-icon> {{ text }}
    <template v-slot:actions>
      <v-btn variant="text" @click="closeSnack"> Fechar </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import messages from "@/constants/messages";
import CustomImgComponent from "@/components/CustomImg/CustomImgComponent.vue";

@Options({
  components: {
    CustomImgComponent,
  },
})
export default class CustomOptionComponent extends Vue {
  public rules = [(value: any) => !!value || "Required.", (value: string) => (value && value.length >= 3) || "Min 3 characters"];
  public title = "";
  public hasObsField = false;
  public typeFragment = "CustomMultiSelectiontFragment";
  public items = [
    {
      id: 1,
      question: "",
      positive: "",
      negative: "",
      neutral: "",
      photo_positive: false,
      photo_negative: false,
      photo_neutral: false,
    },
  ];
  public nextItemId = 2;
  public messages: any = {};
  public isLoadingTable = true;
  public needleSearch: any;
  public snackbar = false;
  public text = "";
  public timeout = 10000;
  public btn_disabled = true;
  public generatedJson: any;
  public form: any;
  public movida_color = "#ff5000";
  public imageSrc = require("@/assets/CustomComponentsImgExamples/CustomOption.png");

  mounted(): void {
    this.messages = messages;
    this.$emit("mounted", this);
  }

  verifyBtnDisabled() {
    if (this.title?.length >= 3 && this.items.every((item) => item.question?.length >= 3) && this.items.every((item) => item.positive?.length >= 3) && this.items.every((item) => item.negative?.length >= 3) && this.items.every((item) => item.neutral?.length >= 3)) {
      this.btn_disabled = false;
    }
    return this.btn_disabled;
  }

  addItem() {
    this.items.push({
      id: this.nextItemId,
      question: "",
      positive: "",
      negative: "",
      neutral: "",
      photo_positive: false,
      photo_negative: false,
      photo_neutral: false,
    });
    this.nextItemId++;
    this.btn_disabled = true;
  }

  removeItem(id: number) {
    const index = this.items.findIndex((item) => item.id === id);
    if (index !== -1) {
      this.items.splice(index, 1);
      this.verifyBtnDisabled();
    }
  }

  changeLoading(): void {
    this.isLoadingTable = !this.isLoadingTable;
  }

  closeSnack(): void {
    this.snackbar = false;
  }

  onInputChange() {
    this.btn_disabled = true;
    this.verifyBtnDisabled();
  }

  onlyOneCheckedBox(id: number, type: string) {
    const index = this.items.findIndex((item) => item.id === id);

    if (type == "positive" && this.items[index].photo_positive) {
      this.items[index].photo_positive = true;
      this.items[index].photo_negative = false;
      this.items[index].photo_neutral = false;
    } else if (type == "negative" && this.items[index].photo_negative) {
      this.items[index].photo_positive = false;
      this.items[index].photo_negative = true;
      this.items[index].photo_neutral = false;
    } else if (type == "neutral" && this.items[index].photo_neutral) {
      this.items[index].photo_positive = false;
      this.items[index].photo_negative = false;
      this.items[index].photo_neutral = true;
    } else {
      this.items[index].photo_positive = false;
      this.items[index].photo_negative = false;
      this.items[index].photo_neutral = false;
    }

    this.btn_disabled = false;
  }

  public updateAttributes(data: any) {
    this.title = data.title;
    this.hasObsField = data.hasObsField;
    this.items.splice(0, 1);
    let nextItemId = 1;
    data.questionList.forEach((item: any) => {
      let photo_positive = false;
      let photo_negative = false;
      let photo_neutral = false;

      if (item.photo == item.optionsListTitle.positive) {
        photo_positive = true;
      } else if (item.photo == item.optionsListTitle.negative) {
        photo_negative = true;
      } else if (item.photo == item.optionsListTitle.neutral) {
        photo_neutral = true;
      }

      this.items.push({
        id: nextItemId,
        question: item.question,
        positive: item.optionsListTitle.positive,
        negative: item.optionsListTitle.negative,
        neutral: item.optionsListTitle.neutral,
        photo_positive: photo_positive,
        photo_negative: photo_negative,
        photo_neutral: photo_neutral,
      });
      nextItemId++;
    });
  }

  async convertToJson(): Promise<void> {
    this.verifyBtnDisabled();
    if (this.typeFragment && !this.btn_disabled) {
      try {
        const question_list = [];

        for (const item of this.items) {
          let photo = "";

          if (item.photo_positive) {
            photo = item.positive;
          } else if (item.photo_negative) {
            photo = item.negative;
          } else if (item.photo_neutral) {
            photo = item.neutral;
          }

          const questionItem = {
            question: item.question,
            ...(photo && { photo }),
            optionsListTitle: {
              positive: item.positive,
              negative: item.negative,
              neutral: item.neutral,
            },
          };

          question_list.push(questionItem);
        }

        const json = {
          title: this.title,
          typeFragment: this.typeFragment,
          hasObsField: this.hasObsField,
          questionList: question_list,
          hidden_component_id: 14, // 14 é componente OPÇÃO SIM / NÃO
        };

        this.generatedJson = json as any;
        return this.generatedJson;
      } catch (error) {
        console.error("Erro na conversão para base64 (custom option)", error);
      }
    }
  }

  async store(): Promise<void> {
    try {
      const jsonPromise = this.convertToJson();
      const json = await jsonPromise;
      const success_error_msg = "Dados gravados";
      const data = {
        success_error_msg: success_error_msg,
        json: json,
      };

      this.$emit("generatedJson", data);
      this.$emit("close-modal");
    } catch (error) {
      const successErrorMsg = "Erro ao tentar gravar os dados";
      console.log("Erro ao gravar os dados (custom option)", error);
    }
  }
}
</script>

<style lang="css">
.default-data-base {
  border: solid #efecec 0.4px;
}

.w-3 {
  width: 10px;
}

.h-3 {
  height: 10px;
}

.mainInputs:not(:last-child) {
  margin-bottom: 15px;
}

.hiddenInput {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  pointer-events: none;
}

.newFileInput {
  height: 60px;
}

.titleSt {
  margin-right: 200px;
}

.hrMargin {
  margin-top: 20px;
}

.questionElement1 {
  margin-top: 20px;
  margin-bottom: -20px;
}

.questionElement2 {
  margin-bottom: -40px;
}

.questionElement3 {
  margin-bottom: 20px;
}

.horizontalScrollLocked {
  overflow-x: hidden;
}
</style>

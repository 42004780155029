<template>
  <div class="home">
    <h1>Início</h1>
    <p>Bem vindo ao ambiente Admin do {{ appName }}.</p>
    <v-row>
      <v-col cols="12" md="12">
        <v-list density="default" class="navbar-left">
          <v-list-item v-for="(item, i) in items" :key="i" :value="item" color="primary">
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import global from "@/constants/global";

@Options({
  components: {},
})
export default class AdminView extends Vue {
  public items = [{ text: "Início", icon: "mdi-monitor-multiple", description: "Página inicial" }];
  public appName = "";
  mounted(): void {
    this.appName = global.title;
  }
}
</script>
<style lang="css" scoped>
.navbar-left .v-list-item--variant-text {
  background: #f6f8f9;
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 12px;
  border: 2px solid #dde6ec;
}

.text-primary {
  color: #ff5000 !important;
}
</style>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "px-6 mt-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavbarLeft = _resolveComponent("NavbarLeft")!
  const _component_NavHeader = _resolveComponent("NavHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, { id: "inspire" }, {
    default: _withCtx(() => [
      _createVNode(_component_NavbarLeft, {
        modules: _ctx.modules,
        modelValue: _ctx.selectedModule,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedModule) = $event))
      }, null, 8, ["modules", "modelValue"]),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_NavHeader, {
            routes: _ctx.functionalities,
            user: _ctx.user
          }, null, 8, ["routes", "user"]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_router_view)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
<template>
  <v-app id="inspire">
    <NavbarLeft :modules="modules" v-model="selectedModule" />
    <v-main>
      <NavHeader :routes="functionalities" :user="user" />
      <div class="px-6 mt-7">
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import NavbarLeft from "@/components/Layout/NavbarLeft.vue";
import NavHeader from "@/components/Layout/NavHeader.vue";

@Options({
  components: { NavHeader, NavbarLeft },
})
export default class AdminPage extends Vue {
  public user = { name: "", email: "" };
  public selectedModule = {};
  public modules = [];
  public functionalities = [];
  mounted(): void {
    const storeUser = localStorage.getItem("DATA_USER");
    if (storeUser) {
      const user = JSON.parse(storeUser);
      this.user.name = user.nome;
      this.user.email = user.email;
    }
  }
}
</script>
<style lang="css">
.grecaptcha-badge {
  display: none;
}
</style>

<template>
  <div class="root">
    <h2>Sair da conta</h2>

    <button @click="doExit">Sair</button>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class LogoutView extends Vue {
  doExit() {
    this.$store.commit("SET_USER_TOKEN", null);
    this.$router.push("/");
  }
}
</script>
<style lang="css">
/* .root {
  width: 400px;
  margin: 0 auto;
  background-color: #fff;
  padding: 30px;
  margin-top: 100px;
  border-radius: 20px;
}
input {
  border: none;
  outline: none;
  border-bottom: 1px solid #ddd;
  font-size: 1em;
  padding: 5px 0;
  margin: 10px 0 5px 0;
  width: 100%;
}
button {
  background-color: #3498db;
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  color: white;
} */
</style>

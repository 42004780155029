<template>
  <fuel-types-component />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import FuelTypesComponent from "@/components/FuelTypes/FuelTypesComponent.vue";

@Options({
  components: {
    FuelTypesComponent,
  },
})
export default class FuelTypesIndexView extends Vue {}
</script>

<template>
  <div class="about">
    <h1>Página nao encontrada</h1>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class V404View extends Vue {}
</script>

<template>
  <item-checklist-component />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ItemChecklistComponent from "@/components/ItemChecklist/ItemChecklistComponent.vue";
import * as services from "@/services";
import { ItemChecklist } from "@/models/itemChecklist/ItemChecklist";
import { ItemChecklistIndexResponse } from "@/dtos/itemChecklist/ItemChecklistIndexResponse";

@Options({
  components: {
    ItemChecklistComponent,
  },
})
export default class ItemChecklistIndexView extends Vue {}
</script>

<template>
  <v-row>
    <v-col cols="8">
      <div class="d-flex justify-space-between">
        <h1 class="titleSt">Componente Opções Sim / Não</h1>
        <a class="text-decoration-none">
          <v-btn class="text-white" color="grey" @click="$emit('close-modal')">Cancelar</v-btn>
          <v-btn class="text-white" color="#ff5000" @click="store" v-bind:disabled="btn_disabled">Salvar</v-btn>
        </a>
      </div>
      <div class="mainInputs">
        <v-text-field v-model="title" label="Título" :rules="rules" hide-details="auto" @keyup="onInputChange()"></v-text-field>
      </div>
      <div>
        <v-switch :color="movida_color" v-model="hasObsField" @change="verifyBtnDisabled" :label="'Possui campo Observação? ' + (hasObsField ? 'Sim' : 'Não')"></v-switch>
      </div>
      <div>
        <hr />
        <h2>Lista de perguntas</h2>
        <div>
          <div v-for="item in items" :key="item.id" class="mainInputs">
            <v-text-field v-model="item.question" label="Pergunta" :rules="rules" hide-details="auto" @keyup="onInputChange()">
              <template v-slot:append>
                <v-icon v-if="item.id != 1" color="red" @click="removeItem(item.id)"> mdi-minus </v-icon>
              </template>
            </v-text-field>
          </div>
          <v-btn @click="addItem">Adicionar pergunta</v-btn>
        </div>
      </div>
    </v-col>

    <v-col cols="4">
      <custom-img-component :imageSrc="imageSrc" />
    </v-col>
  </v-row>

  <v-snackbar v-model="snackbar" :timeout="timeout">
    <v-icon class="ml-1"> mdi-information-outline </v-icon> {{ text }}
    <template v-slot:actions>
      <v-btn variant="text" @click="closeSnack"> Fechar </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import messages from "@/constants/messages";
import CustomImgComponent from "@/components/CustomImg/CustomImgComponent.vue";

@Options({
  components: {
    CustomImgComponent,
  },
})
export default class OptionYesNoComponent extends Vue {
  public rules = [(value: any) => !!value || "Required.", (value: string) => (value && value.length >= 3) || "Min 3 characters"];
  public title = "";
  public hasObsField = false;
  public typeFragment = "CustomMultiSelectiontFragment";
  public items = [{ id: 1, question: "" }];
  public nextItemId = 2;
  public messages: any = {};
  public isLoadingTable = true;
  public needleSearch: any;
  public snackbar = false;
  public text = "";
  public timeout = 10000;
  public btn_disabled = true;
  public generatedJson: any;
  public form: any;
  public movida_color = "#ff5000";
  public imageSrc = require("@/assets/CustomComponentsImgExamples/OptionYesNo.png");

  mounted(): void {
    this.messages = messages;
    this.$emit("mounted", this);
  }

  verifyBtnDisabled() {
    if (this.title?.length >= 3 && this.items.every((item) => item.question?.length >= 3)) {
      this.btn_disabled = false;
    }
    return this.btn_disabled;
  }

  addItem() {
    this.items.push({ id: this.nextItemId, question: "" });
    this.nextItemId++;
    this.btn_disabled = true;
  }

  removeItem(id: number) {
    const index = this.items.findIndex((item) => item.id === id);
    if (index !== -1) {
      this.items.splice(index, 1);
      this.verifyBtnDisabled();
    }
  }

  changeLoading(): void {
    this.isLoadingTable = !this.isLoadingTable;
  }

  closeSnack(): void {
    this.snackbar = false;
  }

  onInputChange() {
    this.btn_disabled = true;
    this.verifyBtnDisabled();
  }

  public updateAttributes(data: any) {
    this.title = data.title;
    this.hasObsField = data.hasObsField;
    this.items.splice(0, 1);
    let nextItemId = 1;
    data.questionList.forEach((question: any) => {
      this.items.push({ id: nextItemId, question: question.question });
      nextItemId++;
    });
  }

  async convertToJson(): Promise<void> {
    this.verifyBtnDisabled();
    if (this.typeFragment && !this.btn_disabled) {
      try {
        const json = {
          title: this.title,
          typeFragment: this.typeFragment,
          hasObsField: this.hasObsField,
          questionList: this.items.map((item) => ({ question: item.question })),
          hidden_component_id: 12, // 12 é componente OPÇÃO SIM / NÃO
        };

        this.generatedJson = json as any;
        return this.generatedJson;
      } catch (error) {
        console.error("Erro na conversão para base64 (option yes no)", error);
      }
    }
  }

  async store(): Promise<void> {
    try {
      const jsonPromise = this.convertToJson();
      const json = await jsonPromise;
      const success_error_msg = "Dados gravados";
      const data = {
        success_error_msg: success_error_msg,
        json: json,
      };

      this.$emit("generatedJson", data);
      this.$emit("close-modal");
    } catch (error) {
      const successErrorMsg = "Erro ao tentar gravar os dados";
      console.log("Erro ao gravar os dados (option yes no)", error);
    }
  }
}
</script>

<style lang="css">
.default-data-base {
  border: solid #efecec 0.4px;
}

.w-3 {
  width: 10px;
}

.h-3 {
  height: 10px;
}

.mainInputs:not(:last-child) {
  margin-bottom: 15px;
}

.hiddenInput {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  pointer-events: none;
}

.newFileInput {
  height: 60px;
}

.titleSt {
  margin-right: 200px;
}
</style>

import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import * as Views from "../views";
import * as pages from "../pages";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "auth",
    component: pages.AuthPage,
    children: [
      {
        path: "",
        name: "login",
        component: Views.LoginView,
      },
      {
        path: "recovery",
        component: Views.RecoveryView,
      },
    ],
  },
  {
    path: "/admin",
    name: "admin",
    component: pages.AdminPage,
    children: [
      {
        name: "Home",
        path: "",
        component: Views.AdminView,
      },
      {
        path: "exit",
        component: Views.LogoutView,
      },
    ],
  },
  {
    path: "/tipos-checklist",
    name: "tipos-checklist",
    component: pages.AdminPage,
    children: [
      {
        name: "Tipos de checklist",
        path: "",
        component: Views.TypeMovingView,
      },
      {
        name: "Editar checklist",
        path: "editar/:id",
        component: Views.EditTypeMovingView,
      },
      {
        name: "Novo checklist",
        path: "novo",
        component: Views.CreateTypeMovingView,
      },
    ],
  },
  {
    path: "/items-checklist",
    name: "items-checklist",
    component: pages.AdminPage,
    children: [
      {
        name: "Itens de checklist",
        path: "",
        component: Views.ItemCheklistView,
      },
    ],
  },
  {
    path: "/tipos-combustiveis",
    name: "tipos-combustiveis",
    component: pages.AdminPage,
    children: [
      {
        name: "Tipos de combustíveis",
        path: "",
        component: Views.FuelTypesView,
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    component: Views.V404View,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

let logoutInterval: number;

router.beforeEach(async (to, from, next) => {
  clearInterval(logoutInterval);
  const listRoutesAllowed = ["login"];
  const tokenJson = localStorage.getItem("BEARER_TOKEN");

  if (tokenJson && to.name === "login") {
    router.push("/admin");
  }

  if (!listRoutesAllowed.includes(<string>to.name)) {
    if (tokenJson) {
      const lastActivity = Date.now();
      const interval = 1000;
      const oneMinute = 60000;
      const logoutMinutes = parseInt(process.env.VUE_APP_LOGOUT_MINUTES, 10) * oneMinute;

      logoutInterval = setInterval(() => {
        if (Date.now() - lastActivity >= logoutMinutes) {
          localStorage.removeItem("BEARER_TOKEN");
          localStorage.removeItem("DATA_USER");
          localStorage.setItem("INACTIVITY_LOGOUT", "1");
          router.push("/");
        }
      }, interval);
    } else {
      next({ name: "login" });
    }
  }
  next();
});
export default router;

<template>
  <header>
    <div class="d-flex justify-space-between">
      <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
      <v-col cols="5" md="5" class="nav-header">
        <v-menu bottom offset-y offset-x>
          <template v-slot:activator="{ props }">
            <v-btn prepend-icon="mdi-account-circle-outline" variant="text" v-bind="props"> {{ user.name }} </v-btn>
          </template>
          <v-list>
            <v-subheader class="d-flex align-center justify-space-between flex-column">
              <div>
                <v-icon class="mr-2">mdi-account-circle-outline</v-icon>
                <span>{{ user.name }}</span>
              </div>
              <span class="ml-2 text-caption">{{ user.email }}</span>
            </v-subheader>
            <v-divider></v-divider>
            <v-list-item v-for="(item, index) in rightMenu" :key="index" :value="index" :to="item.to" :click="item.click">
              <template v-slot:prepend>
                <v-icon :icon="item.icon"></v-icon>
              </template>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-on:click="doExit">
              <template v-slot:prepend>
                <v-icon icon="mdi-exit-to-app"></v-icon>
              </template>
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </div>
    <v-divider class="mx-5"></v-divider>
  </header>
</template>

<script>
export default {
  //TODO<Jean Paul>: Falta migrar este component para classe com typescript
  name: "NavHeader",
  props: {
    routes: {
      type: Array,
      required: true,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    rightMenu: {
      type: Array,
      required: true,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    user: {
      type: Object,
      required: true,
      default: function () {
        return {
          name: "não encontrado",
          email: "não encontrado",
        };
      },
    },
  },
  computed: {
    breadcrumbs() {
      const initialBreadcrumb = this.initialBreadcrumb();
      const currentRoute = this.$route;
      const isFirstLevelRoute = currentRoute.path.lastIndexOf("/") === 0;

      let routes = isFirstLevelRoute ? [currentRoute.matched[0]] : currentRoute.matched;

      if (!this.isFilledModules()) {
        return initialBreadcrumb;
      }

      routes = routes.map((route) => {
        if (!route.children.length) {
          return currentRoute;
        }
        const child = route.children[0];
        return { ...route, name: child.name };
      });

      return [
        initialBreadcrumb[0],
        ...routes.map((item) => {
          return {
            title: item.name,
            href: item.path,
            disabled: false,
          };
        }),
      ];
    },
  },
  methods: {
    isFilledModules() {
      return this.$route.path !== "/admin";
    },
    initialBreadcrumb() {
      return [
        {
          title: "Admin",
          href: "/admin",
          disabled: true,
        },
        {
          title: "Início",
          href: "/admin",
          disabled: false,
        },
      ];
    },
    doExit() {
      localStorage.removeItem("BEARER_TOKEN");
      localStorage.removeItem("DATA_USER");
      this.$router.push("/");
    },
  },
};
</script>
<style lang="css">
.nav-header {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: end;
}
</style>

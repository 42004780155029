import { BaseService } from "../BaseService";
import { TypeMovingStoreRequest } from "@/dtos/typeMoving/TypeMovingStoreRequest";
import { TypeMovingIndexWithPaginateResponse } from "@/dtos/typeMoving/TypeMovingIndexWithPaginateResponse";
class TypeMovingService extends BaseService {
  async getAll(perPage: number, page = 1, needle: string | null = null, sortColumn: string | null = "name") {
    let route = `/typeMovings?page=${page}&order=ASC&column=${sortColumn}&perPage=${perPage}`;
    if (needle) {
      route = route + `&search=${needle}`;
    }
    const response = await this.instance.get<TypeMovingIndexWithPaginateResponse>(route, this.getConfigHeaders());
    return response.data;
  }
  async storeNew(dataRequest: TypeMovingStoreRequest) {
    const route = `/typeMovings/`;
    const response = await this.post(route, dataRequest);

    return response.data;
  }
  async show(id: number) {
    const route = `/typeMovings/${id}`;
    const response = await this.get(route);
    return response.data;
  }
  async update(dataRequest: TypeMovingStoreRequest, id: number) {
    const route = `/typeMovings/${id}`;
    const response = await this.put(route, dataRequest);
    return response.data;
  }
}
export default new TypeMovingService("", "");

<template>
  <v-app id="blank">
    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class AuthPage extends Vue {}
</script>
<style lang="css">
#blank {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  isolation: isolate;
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #ff5000 50%, #edf1f4 30%);
}
</style>

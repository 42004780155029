import { BaseService } from "../BaseService";
import { LoginRequest } from "./requests/LoginRequest";
import { AppResponse } from "./responses/AppResponse";
import { UserResponse } from "./responses/UserResponse";
import { AuthShowDataUserResponse } from "@/dtos/auth/AuthShowDataUserResponse";

export class AuthService extends BaseService {
  private static classInstance?: AuthService;

  private user = "auth/user";
  private userWithToken = "auth/user/with-token";
  private app = "auth/app";

  constructor(token: string) {
    super("", token);
  }

  public static getInstance(token: string) {
    if (!this.classInstance) {
      this.classInstance = new AuthService(token);
    }
    return this.classInstance;
  }

  public async doAuthApp(): Promise<AppResponse> {
    const response = await this.instance.post<AppResponse>(this.app, {});

    return response.data;
  }

  public async doAuthUser(form: LoginRequest): Promise<UserResponse> {
    const response = await this.instance.post<UserResponse>(this.user, form);

    return response.data;
  }

  public async doAuthUserWithToken(form: LoginRequest): Promise<UserResponse> {
    const response = await this.instance.post<UserResponse>(this.userWithToken, form);

    return response.data;
  }

  public async getUserData(): Promise<AuthShowDataUserResponse> {
    const response = await this.instance.get<AuthShowDataUserResponse>(this.user, this.getConfigHeaders());

    return response.data;
  }
}

<template>
  <div class="d-flex justify-center align-center min-h-100vh">
    <v-flex class="justify-center">
      <v-col>
        <v-card elevation="5" class="pa-md-16 pa-sm-4 px-md-16 px-sm-4">
          <v-alert v-if="state.showInactivityAlert" class="mb-4" density="compact" type="warning" border="start" closable>Sua sessão expirou após 30 minutos de inatividade.<br />Realize um novo login</v-alert>
          <v-alert v-if="state.showErrorMessage" closable density="compact" type="error" text="Erro ao tentar fazer login!"></v-alert>
          <v-row no-gutters>
            <v-container>
              <h1 class="text-left neutral_900">Acesse o {{ appName }}</h1>
              <p color="neutral_300">Para continuar, digite seu usuário e senha Movida.</p>
            </v-container>
          </v-row>
          <v-form v-model="valid" name="form-login" @submit.prevent="onSubmit()">
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field :disabled="state.loading" v-model.trim="form.username" type="text" label="Usuário" variant="outlined" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model.trim="form.password"
                    variant="outlined"
                    label="Senha"
                    :append-inner-icon="state?.isVisiblePass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                    @click:append-inner="() => (state = { isVisiblePass: !state?.isVisiblePass })"
                    :type="state?.isVisiblePass ? 'text' : 'password'"
                    outlined
                    dense
                    :disabled="state.loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" class="justify-center">
                  <v-btn :disabled="state.loading" :loading="state.loading" type="submit" color="#ff5000" block> Entrar </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-flex>
  </div>
</template>

<script lang="ts">
import { LoginRequest } from "@/services/auth/requests/LoginRequest";
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";
import global from "@/constants/global";

@Options({
  components: {},
})
export default class LoginView extends Vue {
  public form: LoginRequest = {
    access_token: "",
    username: "",
    password: "",
    recaptcha: "",
  };
  private _state: unknown = {
    showErrorMessage: false,
    isVisiblePass: false,
    loading: false,
    showInactivityAlert: !!localStorage.getItem("INACTIVITY_LOGOUT"),
  };
  get state(): unknown {
    return this._state;
  }
  set state(value: unknown) {
    this._state = Object.assign({}, this._state, value);
  }
  public appName = "";
  public route = "admin";
  mounted(): void {
    this.willMountDataPage();
    this.removeInactivityAlert();
  }
  async willMountDataPage(): Promise<void> {
    this.appName = global.title;
    this.form.username = "";
    this.form.password = "";
    await this.$recaptchaLoaded();
    const recaptchaToken = await this.$recaptcha("login");
    this.$store.commit("SET_RECAPTCHA_TOKEN", recaptchaToken);
    this.form.recaptcha = recaptchaToken;

    const token = new URL(location.href).searchParams.get("token");

    const route = new URL(location.href).searchParams.get("route") ?? "";
    this.route = route == "" ? this.route : route;

    if (token) {
      this.doLogin(token);
    }
  }

  async onSubmit(): Promise<void> {
    return this.doLogin();
  }

  async doLogin(token: string | null = null): Promise<void> {
    this.state = { showErrorMessage: false, loading: true };
    const response = await services.AuthService.getInstance("")
      .doAuthApp()
      .catch(() => {
        this.state = { showErrorMessage: true, loading: false };
      });
    this.$store.commit("SET_BEARER_TOKEN", response?.access_token);
    const loginRequest: LoginRequest = {
      recaptcha: this.form.recaptcha,
      username: this.form.username,
      password: this.form.password,
      access_token: this.$store.getters.tokens.application,
    } as LoginRequest;

    let userResponse = null;

    if (token) {
      loginRequest.password = ``;
      loginRequest.username = ``;
      loginRequest.user_token = token;
      userResponse = await services.AuthService.getInstance("").doAuthUserWithToken(loginRequest);
    } else {
      userResponse = await services.AuthService.getInstance("").doAuthUser(loginRequest);
    }

    if (userResponse.status) {
      this.saveOnClientLocalStorage("BEARER_TOKEN", userResponse.data.token);
      const userData = await services.AuthService.getInstance(userResponse.data.token).getUserData();
      if (userData.status) {
        this.$store.commit("AUTH", true);
        this.saveOnClientLocalStorage("DATA_USER", JSON.stringify(userData.data));
      }
      this.$router.push(this.route);
    } else {
      this.state = { showErrorMessage: true, loading: false };
    }
  }
  saveOnClientLocalStorage(key: string, value: string) {
    localStorage.setItem(key, value);
  }
  removeInactivityAlert() {
    localStorage.removeItem("INACTIVITY_LOGOUT");
  }
}
</script>
<style lang="css" scoped>
.v-card {
  border-radius: 12px;
}
</style>

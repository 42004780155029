<template>
  <v-container fluid>
    <v-card max-width="1200" :color="main_card_color" class="mx-auto min-height-card-1">
      <v-container fluid>
        <v-row class="fill-height" align="center" justify="center">
          <v-col class="container-1-col-1">
            <v-text-field label="Título" hide-details="auto" v-model="header"></v-text-field>
          </v-col>
          <v-col class="container-1-col-2">
            <v-switch :color="movida_color" v-model="last_checklist" :label="'Buscar Checklist anterior? ' + (last_checklist ? 'Sim' : 'Não')"></v-switch>
          </v-col>
          <v-col class="container-1-col-2">
            <v-switch :color="movida_color" v-model="plate_input" :label="'Checklist com placa? ' + (plate_input ? 'Sim' : 'Não')"></v-switch>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>

  <v-container fluid>
    <v-card max-width="1200" :color="main_card_color" class="mx-auto">
      <v-container fluid>
        <v-row dense>
          <v-col>
            <h2 class="text-center">Componentes da Tela</h2>
            <v-card :color="son_card_color" class="min-height-card-2">
              <draggable :list="screen_components" group="itens" @change="log" @add="added" itemKey="name" class="list-items">
                <template #item="{ element, index }">
                  <v-container class="pa-1">
                    <v-card class="mx-12 grab-cursor" :color="son_card_color" @dblclick="openModal(element)">
                      <v-col class="mx-auto">
                        <v-row>
                          <v-col cols="10" class="text-left">
                            <v-span>
                              {{
                                element.name.length > 25
                                  ? element.name.slice(0, 22) + "..."
                                  : this.component_jsons[element.id]?.title
                                  ? element.name.length + this.component_jsons[element.id]?.title.length + 3 > 25
                                    ? (element.name + " - " + this.component_jsons[element.id]?.title).slice(0, 22) + "..."
                                    : element.name + " - " + this.component_jsons[element.id]?.title
                                  : element.name
                              }}
                            </v-span>
                          </v-col>
                          <v-col cols="2" class="text-right">
                            <v-icon :color="close_icon_color" @click="removeAt(index)"> mdi-close </v-icon>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-card>
                  </v-container>
                </template>
              </draggable>
            </v-card>
          </v-col>

          <v-col>
            <h2 class="text-center">Lista de componentes</h2>
            <v-card :color="son_card_color" class="min-height-card-2">
              <draggable :list="available_componentes" :group="{ name: 'itens', pull: 'clone', put: false }" :clone="cloneItem" @change="log" itemKey="name" :sort="false" class="list-items">
                <template #item="{ element }">
                  <v-container class="pa-1">
                    <v-card class="mx-12 grab-cursor" :color="son_card_color">
                      <v-col class="mx-auto">
                        <v-span>{{ element.name.length > 25 ? element.name.slice(0, 22) + "..." : element.name }}</v-span>
                      </v-col>
                    </v-card>
                  </v-container>
                </template>
              </draggable>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>

  <v-container>
    <v-dialog v-model="modal_open" @click:outside="modal_open = false" width="auto" content-class="dialog-model-content" scrollable transition="dialog-bottom-transition">
      <div class="component-st">
        <component @close-modal="closeModal" @generatedJson="jsonData" @mounted="formReady" :is="modal_form_component" />
      </div>
    </v-dialog>
  </v-container>

  <v-snackbar v-model="snackbar" :timeout="timeout">
    <v-icon class="ml-1"> mdi-information-outline </v-icon> {{ success_error_msg }}
    <template v-slot:actions>
      <v-btn variant="text" @click="closeSnack"> Fechar </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import draggable from "vuedraggable";
import PlateComponent from "@/components/Plate/PlateComponent.vue";
import PhotoComponent from "@/components/Photo/PhotoComponent.vue";
import WitnessSignatureComponent from "@/components/WitnessSignature/WitnessSignatureComponent.vue";
import AcceptanceTermComponent from "@/components/AcceptanceTerm/AcceptanceTermComponent.vue";
import DamagesComponent from "@/components/Damages/DamagesComponent.vue";
import SignatureComponent from "@/components/Signature/SignatureComponent.vue";
import TextFieldObservationComponent from "@/components/TextFieldObservation/TextFieldObservationComponent.vue";
import PreviewList from "@/components/PreviewList/PreviewList.vue";
import Preview from "@/components/Preview/Preview.vue";
import SpecificSignatureComponent from "@/components/SpecificSignature/SpecificSignatureComponent.vue";
import VideoComponent from "@/components/Video/VideoComponent.vue";
import OptionYesNoComponent from "@/components/OptionYesNo/OptionYesNoComponent.vue";
import SpinnerDropdownListComponent from "@/components/SpinnerDropdownList/SpinnerDropdownListComponent.vue";
import CustomOptionComponent from "@/components/CustomOption/CustomOptionComponent.vue";
import ListTextInputComponent from "@/components/ListTextInput/ListTextInputComponent.vue";

@Options({
  components: {
    draggable,
    PlateComponent,
    PhotoComponent,
    WitnessSignatureComponent,
    AcceptanceTermComponent,
    DamagesComponent,
    SignatureComponent,
    TextFieldObservationComponent,
    PreviewList,
    Preview,
    SpecificSignatureComponent,
    VideoComponent,
    OptionYesNoComponent,
    SpinnerDropdownListComponent,
    CustomOptionComponent,
    ListTextInputComponent,
  },
})
export default class CustomJsonComponent extends Vue {
  public screen_components: Array<any> = [];
  public available_componentes: Array<any> = [
    { id: 0, hidden_component_id: 6, name: "Assinatura", component: SignatureComponent },
    { id: 0, hidden_component_id: 10, name: "Assinatura especifica", component: SpecificSignatureComponent },
    { id: 0, hidden_component_id: 3, name: "Assinatura Testemunha", component: WitnessSignatureComponent },
    { id: 0, hidden_component_id: 5, name: "Avarias", component: DamagesComponent },
    { id: 0, hidden_component_id: 7, name: "Campo Texto / Observacao", component: TextFieldObservationComponent },
    { id: 0, hidden_component_id: 2, name: "Foto", component: PhotoComponent },
    { id: 0, hidden_component_id: 1, name: "Placa", component: PlateComponent },
    { id: 0, hidden_component_id: 9, name: "Preview", component: Preview },
    { id: 0, hidden_component_id: 8, name: "Preview List", component: PreviewList },
    { id: 0, hidden_component_id: 4, name: "Termo de Aceite", component: AcceptanceTermComponent },
    { id: 0, hidden_component_id: 11, name: "Video", component: VideoComponent },
    { id: 0, hidden_component_id: 12, name: "Opções Sim / Não", component: OptionYesNoComponent },
    { id: 0, hidden_component_id: 13, name: "Lista Spinner Dropdown", component: SpinnerDropdownListComponent },
    { id: 0, hidden_component_id: 14, name: "Opções Personalizadas", component: CustomOptionComponent },
    { id: 0, hidden_component_id: 15, name: "Lista Text Input", component: ListTextInputComponent },
  ];
  public component_jsons: Array<any> = []; // listagem dos jsons de cada componenete
  public header = "";
  public last_checklist = false;
  public plate_input = true;
  public id = 0;
  public next_id = 1;
  public modal_open = false;
  public modal_form_component: any;
  public form: any = {};
  public snackbar = false;
  public success_error_msg = "";
  public main_card_color = "#ebeae8";
  public movida_color = "#ff5000";
  public son_card_color = "#f5f5f5";
  public close_icon_color = "red";

  mountScreen(string_json: string) {
    const data = JSON.parse(string_json);
    this.header = data?.header;
    this.last_checklist = data?.searchOldChecklistType == 1 ? true : false;
    this.plate_input = !data?.noPlateInput;

    let list_id_counter = 1;
    for (const component of data.CustomViewResponseList) {
      const found_component = this.available_componentes.find((comp) => comp.hidden_component_id === component.hidden_component_id);
      if (found_component) {
        const found_component_copy = { ...found_component };
        found_component_copy.id = list_id_counter;
        this.screen_components.push(found_component_copy);
        this.component_jsons[found_component_copy.id] = component;
      }

      list_id_counter++;
    }

    this.next_id = list_id_counter;
  }

  cloneItem(item: any) {
    const return_item = { ...item };
    return_item.id = this.next_id;
    return return_item;
  }

  added() {
    this.next_id++;
  }

  removeAt(index: number) {
    this.screen_components.splice(index, 1);
  }

  openModal(element: any) {
    const id = element.id;
    if (id in this.component_jsons) {
      this.form = this.component_jsons[id];
    }
    this.id = id;
    this.modal_form_component = element.component;
    this.modal_open = true;
  }

  closeModal() {
    this.modal_open = false;
  }

  closeSnack() {
    this.snackbar = false;
  }

  jsonData(data: any) {
    this.component_jsons[this.id] = data.json;
    this.success_error_msg = data.success_error_msg;
    this.snackbar = true;
  }

  formReady(component: any) {
    if (this.component_jsons[this.id]) {
      component.updateAttributes(this.component_jsons[this.id]);
    }
  }

  getJsonData() {
    const json_data = {
      header: this.header,
      searchOldChecklistType: this.last_checklist ? 1 : 0,
      plateInput: this.plate_input,
      screen_components: this.screen_components,
      component_jsons: this.component_jsons,
    };
    return json_data;
  }

  getScreenAndJsonComponentLists() {
    const lists = {
      screen_components: this.screen_components,
      component_jsons: this.component_jsons,
    };
    return lists;
  }
}
</script>

<style lang="css">
.min-height-card-1 {
  min-height: 50px;
}

.min-height-card-2 {
  min-height: 114px;
}

.container-1-col-1 {
  height: 78px;
  margin-right: 7%;
}

.container-1-col-2 {
  height: 78px;
}

.dialog-model-content {
  background-color: #f5f5f5;
  border-radius: 5px;
}

.component-st {
  margin: 20px;
  overflow-y: auto;
}

.grab-cursor {
  cursor: grab;
}

.list-items {
  height: 70vh;
  overflow-y: auto;
}
</style>

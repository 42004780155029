<template>
  <div class="d-flex justify-space-between">
    <h1 class="">Tipos de checklist</h1>
  </div>
  <v-row class="mb-3 flex justify-between">
    <v-col cols="7">
      <v-text-field label="Buscar..." prepend-inner-icon="mdi-magnify" clearable single-line density="compact" variant="solo" hide-details v-model="needleSearch" @input="searchByNeedle" @click:clear="clearFilter"></v-text-field>
    </v-col>
    <v-col cols="5" :align="'right'">
      <v-btn v-on:click="goToCreate">Novo</v-btn>
    </v-col>
  </v-row>
  <v-data-table v-model:items-per-page="itemsPerPage" v-on:update:items-per-page="setItemsPerPage" on-c :headers="headers" :items="items" :loading="isLoadingTable" item-value="type_name" class="elevation-1" hide-default-footer to="asd">
    <template v-slot:[`item.options`]="{ item }">
      <v-menu bottom left>
        <template v-slot:activator="{ props }">
          <v-btn dark icon v-bind="props" style="box-shadow: none">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-on:click="goToUpdate(item.id)">
            <v-list-item-title>Editar</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
  <v-row class="mt-2 d-flex align-center" justify="center">
    <div class="text-center">
      <v-pagination v-model="currentPage" :length="numberOfPages" :total-visible="7" @next="nextPage" @prev="prevPage" @update:modelValue="loadPage" color="#ff5000"></v-pagination>
      <div> {{ pageFrom }} a {{ pageTo }} de {{ totalOfRows }} resultados </div>
    </div>
  </v-row>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";
import { TypeMoving } from "@/models/typeMoving/TypeMoving";
import { TypeMovingIndexWithPaginateResponse } from "@/dtos/typeMoving/TypeMovingIndexWithPaginateResponse";

@Options({
  components: {},
})
export default class TypeMovingIndexView extends Vue {
  public items: Array<TypeMoving> = [];
  public currentPage = 0;
  public numberOfPages = 0;
  public itemsPerPage = 10;
  public totalOfRows = 0;
  public pageFrom = 0;
  public pageTo = 1;

  public headers = [
    { title: "Nome", align: "start", key: "type_name", sortable: false },
    { title: "Ações", align: "end", key: "options", sortable: false },
  ];
  public sortSelected = "type_name";
  public isLoadingTable = true;
  public needleSearch = null;
  mounted(): void {
    this.willMountDataPage();
  }
  async willMountDataPage(): Promise<void> {
    const { currentPage } = this;
    const response = await services.TypeMovingService.getAll(currentPage);

    this.unMountRequest(response);

    this.changeLoading();
  }
  nextPage(): void {
    this.changeLoading();
    this.getList(this.currentPage, this.needleSearch, this.sortSelected, this.itemsPerPage);
  }
  setItemsPerPage(value: number): number {
    this.changeLoading();

    this.itemsPerPage = value;
    this.currentPage = 1;
    this.getList(this.currentPage, this.needleSearch, this.sortSelected, this.itemsPerPage);
    return value;
  }
  prevPage(): void {
    this.changeLoading();
    this.getList(this.currentPage, this.needleSearch, this.sortSelected, this.itemsPerPage);
  }
  loadPage(value: number): void {
    this.getList(value, this.needleSearch, this.sortSelected, this.itemsPerPage);
    this.changeLoading();
  }
  changeLoading(): void {
    this.isLoadingTable = !this.isLoadingTable;
  }
  async getList(page: number, needle: string | null = null, sortColumn: string | null = null, perPage = 10) {
    try {
      if (this.isNullableSort(sortColumn)) {
        this.resetSortSelected();
        sortColumn = this.sortSelected;
      }
      const response = await services.TypeMovingService.getAll(perPage, page, needle, sortColumn);
      this.unMountRequest(response);
    } catch (e) {
      console.log("ERRO", e);
    }
    this.changeLoading();
  }
  private isNullableSort(sort: string | null) {
    return sort === null;
  }
  private resetSortSelected() {
    this.loadSortSelected();
  }
  private loadSortSelected() {
    this.sortSelected = this.headers[0].key;
  }
  private unMountRequest(response: TypeMovingIndexWithPaginateResponse) {
    if (response.data) {
      this.currentPage = response.data.current_page;
      this.items = response.data.data as Array<TypeMoving>;
      this.numberOfPages = response.data.last_page;
      this.itemsPerPage = response.data.per_page;
      this.totalOfRows = response.data.total;
      this.pageFrom = response.data.from;
      this.pageTo = response.data.to;
    }
  }

  goToCreate() {
    this.$router.push("/tipos-checklist/novo");
  }
  goToUpdate(id: any) {
    this.items.forEach((element, index) => {
      if (element.id == id) {
        const data = this.items[index];
        sessionStorage.setItem("tipoChecklist-" + id, JSON.stringify(data));
      }
    });
    this.$router.push("/tipos-checklist/editar/" + id);
  }

  searchByNeedle() {
    if (this.needleSearch != null && Array.from(this.needleSearch).length > 1) {
      this.changeLoading();
      this.items = [];
      this.getList(1, this.needleSearch);
    } else if (this.needleSearch == "") {
      this.clearFilter();
    }
  }
  clearFilter() {
    this.changeLoading();
    this.items = [];
    this.currentPage = 1;
    this.getList(1);
  }
}
</script>
<style lang="css">
.default-data-base {
  border: solid #efecec 0.4px;
}

.w-3 {
  width: 10px;
}

.h-3 {
  height: 10px;
}
.v-table .v-table__wrapper > table > tbody > tr:not(:last-child) > td {
  height: 58px;
}
</style>

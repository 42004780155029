export default {
  title: process.env.VUE_APP_NAME,
  description: "",
  company: "Movida",
  image: "/logo@2x.png",
  url: process.env.BASE_URL,
  lang: "pt-BR",
  slogan: "Web Development",
  copyright: "&copy; Todos os direitos reservados pela Movida",
};

import { BaseService } from "../BaseService";
import { FuelTypeStoreRequest } from "@/dtos/fuelTypes/FuelTypeStoreRequest";
import { FuelTypesIndexResponse } from "@/dtos/fuelTypes/FuelTypesIndexResponse";

class FuelTypesService extends BaseService {
  async getAll() {
    const response = await this.instance.get<FuelTypesIndexResponse>("fuelTypes?status=any", this.getConfigHeaders());
    return response.data;
  }

  async update(dataRequest: FuelTypeStoreRequest, id: number) {
    const route = `/fuelTypes/${id}`;
    const response = await this.put(route, dataRequest);
    return response.data;
  }
}

export default new FuelTypesService("", "");

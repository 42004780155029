<template>
  <v-row>
    <v-col cols="8">
      <div class="d-flex justify-space-between">
        <h1 class="titleSt">Componente Vídeo</h1>
        <a class="text-decoration-none">
          <v-btn class="text-white" color="black" @click="removeIcon">Remover ícone</v-btn>
          <v-btn class="text-white" color="grey" @click="$emit('close-modal')">Cancelar</v-btn>
          <v-btn class="text-white" color="#ff5000" @click="store" v-bind:disabled="btn_disabled">Salvar</v-btn>
        </a>
      </div>
      <div class="mainInputs">
        <v-text-field v-model="title" label="Título" :rules="rules" hide-details="auto" @keyup="onInputChange()"></v-text-field>
      </div>
      <div class="mainInputs">
        <v-text-field v-model="description" label="Descrição" :rules="rules" hide-details="auto" @keyup="onInputChange()"></v-text-field>
      </div>
      <div class="mainInputs" @click="simulateFileInputClick">
        <v-file-input ref="fileInput" class="hiddenInput" v-model="icon" label="Icone" accept="image/*" prepend-icon="mdi-camera" hide-details="auto" @change="onFileChange($event)"></v-file-input>

        <v-text-field v-model="iconName" class="newFileInput" :class="fileErrorColor" label="Icone">
          <template v-slot:prepend-inner>
            <v-icon :class="fileErrorIconColor">mdi-camera</v-icon>
          </template>
        </v-text-field>
        <v-span class="fileErrorMsg">{{ this.fileErrorMsg }}</v-span>
      </div>
    </v-col>

    <v-col cols="4">
      <custom-img-component :imageSrc="imageSrc" />
    </v-col>
  </v-row>

  <v-snackbar v-model="snackbar" :timeout="timeout">
    <v-icon class="ml-1"> mdi-information-outline </v-icon> {{ text }}
    <template v-slot:actions>
      <v-btn variant="text" @click="closeSnack"> Fechar </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import messages from "@/constants/messages";
import CustomImgComponent from "@/components/CustomImg/CustomImgComponent.vue";

@Options({
  components: {
    CustomImgComponent,
  },
})
export default class VideoComponent extends Vue {
  public title = "";
  public rules = [(value: any) => !!value || "Required.", (value: string) => (value && value.length >= 3) || "Min 3 characters"];
  public description = "";
  public icon: any;
  public iconName = "";
  public typeFragment = "CustomVideoFragment";
  public messages: any = {};
  public isLoadingTable = true;
  public needleSearch: any;
  public snackbar = false;
  public text = "";
  public timeout = 10000;
  public btn_disabled = true;
  public base64DataOrUrl = "";
  public fileErrorMsg = "";
  public fileErrorColor = "fileErrorColorBlack";
  public fileErrorIconColor = "fileErrorIconBlack";
  public generatedJson: any;
  public form: any;
  public imageSrc = require("@/assets/CustomComponentsImgExamples/Video.png");

  mounted(): void {
    this.messages = messages;
    this.$emit("mounted", this);
  }

  changeLoading(): void {
    this.isLoadingTable = !this.isLoadingTable;
  }

  closeSnack(): void {
    this.snackbar = false;
  }

  onInputChange() {
    this.btn_disabled = true;
    if (this.title.length >= 3 && this.description.length >= 3) {
      this.btn_disabled = false;
    }
  }

  removeIcon() {
    if (this.icon && this.iconName && this.base64DataOrUrl) {
      this.btn_disabled = false;
      this.icon = "";
      this.iconName = "";
      this.base64DataOrUrl = "";
    }
  }

  onFileChange(file: File) {
    this.btn_disabled = true;
    this.fileErrorMsg = "";
    this.iconName = "";
    this.fileErrorColor = "fileErrorColorBlack";
    this.fileErrorIconColor = "fileErrorIconBlack";

    let event = file as any;
    event = event.target.files[0];

    const allowedExtensions = ["jpg", "jpeg", "png", "gif"];

    let formattedExtensions = allowedExtensions.slice(0, -1).join(", ");
    const lastExtension = allowedExtensions.slice(-1);
    formattedExtensions = formattedExtensions + " e " + lastExtension;

    const fileExtension = event?.name.split(".").pop()?.toLowerCase();
    const msg = "Formato inválido. Formatos permitidos: " + formattedExtensions + ".";
    if (!allowedExtensions.includes(fileExtension)) {
      this.fileErrorColor = "fileErrorRed";
      this.fileErrorIconColor = "fileErrorIconRed";
      this.fileErrorMsg = msg;
      this.icon = undefined;
      return;
    }

    this.icon = event as any;
    this.iconName = event.name;

    if (event.name && this.title.length >= 3 && this.description.length >= 3) {
      this.btn_disabled = false;
    }
  }

  simulateFileInputClick() {
    const fileInputElement = this.$refs.fileInput as any;
    fileInputElement.$el.querySelector("input[type=file]").click();
  }

  public updateAttributes(data: any) {
    this.title = data.title;
    this.description = data.description;
    this.iconName = data.hidden_icon_name;
    this.base64DataOrUrl = data.icon;
    this.icon = new File([""], "simulated_file.simulated_file", { type: "simulated_file" });
  }

  async convertToJson(): Promise<void> {
    if (this.typeFragment && this.title.length >= 3 && this.description.length >= 3) {
      try {
        let imgBase64 = this.base64DataOrUrl;
        if (this.base64DataOrUrl.length < 5 && this.icon) {
          const imgBase64Promise = this.imageToBase64(this.icon);
          imgBase64 = await imgBase64Promise;
        }

        const json = {
          title: this.title,
          typeFragment: this.typeFragment,
          description: this.description,
          icon: imgBase64,
          hidden_component_id: 11, // 11 é componente VIDEO
          hidden_icon_name: this.iconName,
        };

        this.generatedJson = json as any;
        return this.generatedJson;
      } catch (error) {
        console.error("Erro na conversão para base64 (video)", error);
      }
    }
  }

  imageToBase64(data: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64Data = reader.result as string;
        resolve(base64Data);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(data);
    });
  }

  async store(): Promise<void> {
    try {
      const jsonPromise = this.convertToJson();
      const json = await jsonPromise;
      const success_error_msg = "Dados gravados";
      const data = {
        success_error_msg: success_error_msg,
        json: json,
      };

      this.$emit("generatedJson", data);
      this.$emit("close-modal");
    } catch (error) {
      const successErrorMsg = "Erro ao tentar gravar os dados";
      console.log("Erro ao gravar os dados (video)", error);
    }
  }
}
</script>

<style lang="css">
.default-data-base {
  border: solid #efecec 0.4px;
}

.w-3 {
  width: 10px;
}

.h-3 {
  height: 10px;
}

.mainInputs:not(:last-child) {
  margin-bottom: 15px;
}

.hiddenInput {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  pointer-events: none;
}

.newFileInput {
  height: 60px;
}
.fileErrorColorBlack {
  color: black;
  opacity: 0.8;
  pointer-events: none;
}

.fileErrorRed .v-label {
  color: #b00020;
  opacity: 1;
  pointer-events: none;
}

.fileErrorIconBlack {
  color: black;
}

.fileErrorIconRed {
  opacity: 1;
  color: #b00020;
}

.fileErrorMsg {
  font-size: 0.75rem;
  color: #b00020;
  margin-left: 16px;
}

.titleSt {
  margin-right: 200px;
}
</style>

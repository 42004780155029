<template>
  <v-container class="image-container">
    <v-img class="image-centered" :src="this.imageSrc"></v-img>
  </v-container>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";

@Options({
  components: {},
})
export default class CustomImgComponent extends Vue {
  @Prop() public imageSrc!: string;

  mounted(): void {
    this.$emit("mounted", this);
  }
}
</script>

<style lang="css">
.default-data-base {
  border: solid #efecec 0.4px;
}

.image-centered {
  max-width: 100%;
  max-height: 100%;
  width: 500px;
  height: 500px;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.component-st {
  margin: 20px;
  overflow-y: auto;
}

.dialog-model-content {
  background-color: #f5f5f5;
  border-radius: 5px;
}
</style>

<template>
  <div v-if="loading" class="overlay-background">
    <v-progress-circular indeterminate size="100" color="primary"></v-progress-circular>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class LoadingOverlay extends Vue {
  public loading = false;

  show() {
    this.loading = true;
  }

  hide() {
    this.loading = false;
  }
}
</script>

<style scoped>
.overlay-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  #z-index: 9999;
  #background-color: white;
  #opacity: 0.9;
}
</style>

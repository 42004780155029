import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "image-container" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_img, {
        class: "image-centered",
        src: this.imageSrc
      }, null, 8, ["src"])
    ]),
    _: 1
  }))
}
<template>
  <div class="d-flex justify-space-between">
    <v-container fluid>
      <div class="container-wrapper">
        <v-row class="fill-height" align="center" justify="center">
          <v-col>
            <h1 class="">Editar checklist</h1>
          </v-col>
          <v-col align="end">
            <a class="text-decoration-none">
              <v-btn class="text-white" color="grey" href="/tipos-checklist">Cancelar</v-btn>
              <v-btn class="text-white" color="#FF5000" @click="update" v-bind:disabled="btn_disabled">Salvar</v-btn>
              <v-btn class="text-white" color="orange-darken-3" @click="downloadJson" v-bind:disabled="btn_disabled">Salvar e baixar json</v-btn>
            </a>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
  <div>
    <v-container fluid>
      <v-card max-width="1200" :color="main_card_color" class="mx-auto min-height-card-1">
        <v-text-field label="Nome" :rules="rules" hide-details="auto" v-model="type_name"></v-text-field>
      </v-card>
    </v-container>
    <custom-json-component ref="CustomJsonComponentRef"></custom-json-component>
  </div>
  <v-snackbar v-model="snackbar" :timeout="timeout">
    <v-icon class="ml-1"> mdi-information-outline </v-icon> {{ text }}
    <template v-slot:actions>
      <v-btn variant="text" @click="closeSnack"> Fechar </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";
import { TypeMovingStoreRequest } from "@/dtos/typeMoving/TypeMovingStoreRequest";
import messages from "@/constants/messages";
import CustomJsonComponent from "@/components/CustomJson/CustomJsonComponent.vue";

@Options({
  components: {
    CustomJsonComponent,
  },
})
export default class TypeMovingCreateView extends Vue {
  public rules = [(value: any) => !!value || "Required.", (value: string) => (value && value.length >= 3) || "Min 3 characters"];
  public type_name = "";
  public download_json = false;
  public messages = {};
  public sortSelected = "name";
  public isLoadingTable = true;
  public needleSearch = null;
  public id: any = 0;
  public snackbar = false;
  public snackbar_redirect = false;
  public text = "";
  public timeout = 10000;
  public btn_disabled = false;

  mounted(): void {
    this.id = Number(this.$route.params.id);
    this.checkStorage();
    this.messages = messages;
  }

  checkStorage(): void {
    const data = sessionStorage.getItem("tipoChecklist-" + this.id);
    if (data !== null) {
      const storedData = JSON.parse(data);
      this.updateData(storedData);
    } else {
      this.getData(this.id);
    }
  }

  updateData(storedData: Record<string, any>): void {
    this.type_name = storedData.type_name;

    if (storedData?.custom_checklist_json) {
      const custom_checklist_json = storedData.custom_checklist_json;
      const customJsonComponent = this.$refs.CustomJsonComponentRef as CustomJsonComponent;
      customJsonComponent.mountScreen(custom_checklist_json);
    }
  }

  changeLoading(): void {
    this.isLoadingTable = !this.isLoadingTable;
  }

  closeSnack(): void {
    this.snackbar = false;
    if (this.snackbar_redirect) {
      this.$router.push("/tipos-checklist");
    }
  }

  downloadJson() {
    this.download_json = true;
    this.update(event);
    this.download_json = false;
  }

  checkIdsExistence(lists: any) {
    /*
      Compara as listas de componentes na tela com a lista de componentes
      que foram preenchidos para saber se um componente foi adicionado.
      Caso exista um componente que foi adicionado, mas não foi preenchido, então retorna false.
      Caso todos os componentes que foram adicionados foram também preenchidos, retorna true.
    */
    const { screen_components, component_jsons } = lists;

    for (const item of screen_components) {
      const id = item.id;

      if (!component_jsons[id]) {
        return false;
      }
    }

    return true;
  }

  async update(event: any): Promise<void> {
    this.btn_disabled = true;
    const customJsonComponent = this.$refs.CustomJsonComponentRef as CustomJsonComponent;
    const jsonData = customJsonComponent.getJsonData();
    const lists = customJsonComponent.getScreenAndJsonComponentLists();
    const check_ids_existence = this.checkIdsExistence(lists);
    if (!check_ids_existence) {
      this.text = messages.incomplete_component;
      this.snackbar = true;
      this.btn_disabled = false;
      return;
    }

    const CustomViewResponseList: Array<any> = [];
    const screenComponents = jsonData.screen_components;
    for (const component of screenComponents) {
      const componentId = component.id;
      if (jsonData.component_jsons[componentId]) {
        CustomViewResponseList.push(jsonData.component_jsons[componentId]);
      }
    }

    const finalJson = {
      header: jsonData.header,
      type: this.id.toString(),
      searchOldChecklistType: jsonData.searchOldChecklistType,
      noPlateInput: !jsonData.plateInput,
      CustomViewResponseList: CustomViewResponseList,
    };

    const storeRequest: TypeMovingStoreRequest = {
      type_name: this.type_name,
      custom_checklist_json: JSON.stringify(finalJson),
    };

    if (this.download_json) {
      // baixa o json que é gerado antes de salvá-lo

      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");
      const date_time = `${year}_${month}_${day}_${hours}_${minutes}_${seconds}`;

      let json_file_name = this.type_name + "_" + date_time + ".json";
      json_file_name = json_file_name.replace(/\s/g, "_");

      const blob = new Blob([storeRequest.custom_checklist_json], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = json_file_name;
      downloadLink.click();
      URL.revokeObjectURL(url);
    }

    const response = await services.TypeMovingService.update(storeRequest, this.id);
    if (response.success) {
      this.text = messages.success;
      this.snackbar = true;
      this.snackbar_redirect = true;
    } else {
      this.snackbar = true;
      this.text = messages.fail;
      this.btn_disabled = false;
    }

    setTimeout(this.closeSnack, this.timeout);
  }

  async getData(id: number) {
    try {
      const response = await services.TypeMovingService.show(id);
      this.updateData(response.data);
      sessionStorage.setItem("tipoChecklist-" + id, JSON.stringify(response.data));
    } catch (e) {
      console.log("ERRO", e);
    }
    this.changeLoading();
  }
}
</script>
<style lang="css">
.default-data-base {
  border: solid #efecec 0.4px;
}

.w-3 {
  width: 10px;
}

.h-3 {
  height: 10px;
}

.container-wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
</style>
